
class Logger {
    public static log(str: string): void {
        if (process.env.REACT_APP_OMNIA_ENV === 'dev') {
            console.log(str);
        }
    }
}

export default Logger;
