import Constants from '../configuration/constants';
import TextLink from './TextLink';

const ContactUs = () => {
    return (
        <TextLink href={`${Constants.frontendURL}${Constants.contactPagePath}`} text='contact us'/>
    );
}

export default ContactUs
