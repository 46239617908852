import './pagefooter.scss';

import { ReactElement } from 'react';

import { useNavigate } from 'react-router';

import Constants from '../configuration/constants';
import TextLink from './TextLink';

interface FooterLegalPolicyLink {
    text: string;
    path: string;
}

const footerlegalPolicyLinks: FooterLegalPolicyLink[] = [
    {text: 'Terms and Conditions', path: Constants.termsAndConditionsPolicyPagePath},
    {text: 'Privacy Policy', path: Constants.privacyPolicyPagePath},
    {text: 'Cookie Policy', path: Constants.cookiePolicyPagePath},
    {text: 'Acceptable Use Policy', path: Constants.acceptableUsePolicyPagePath},
    {text: 'Disclaimer Policy', path: Constants.disclaimerPolicyPagePath},
    {text: 'Refund Policy', path: Constants.refundPolicyPagePath}
]


interface PageFooterProps {
    backgroundColour?: 'black' | 'off-white'
}

const PageFooter = (props: PageFooterProps): ReactElement => {
    const { backgroundColour } = props;

    const navigate = useNavigate();

    return (
        <div className={`page-footer ${backgroundColour ? backgroundColour : ''}`}>
            <div className='page-central-footer'>
                <hr/>
                <div className='page-central-footer-content'>
                    <img className='footer-logo' src={backgroundColour === 'black' ?
                        require('../assets/images/omnia-logos/omnia-name-logo-white.png')
                        :
                        require('../assets/images/omnia-logos/omnia-name-logo-black.png')
                    }/>
                        
                    <div className='footer-legal-content'>
                        <h2>Legal</h2>
                        { footerlegalPolicyLinks.map((item, index) => (
                            <p key={index}><TextLink href={Constants.frontendURL + item.path} text={item.text} colour={backgroundColour === 'black' ? 'white' : 'black'}/></p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageFooter;
