interface ConfigurationInterface {
    frontendURL: string;
    backendURL: string;
}

const configuration: ConfigurationInterface = {
    frontendURL: process.env.REACT_APP_OMNIA_FRONTEND_URL || 'https://thisisomnia.com',
    backendURL: process.env.REACT_APP_OMNIA_BACKEND_URL || 'https://thisisomnia-backend.com'
};

export default configuration;
