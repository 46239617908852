import './signedinhomepage.scss';

import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import OmniaAccountBalance from '../../classes/OmniaAccountBalance';
import OmniaDailyRevenuesForCurrency
  from '../../classes/OmniaDailyRevenueForCurrency';
import TransactionTotal from '../../classes/TransactionTotal';
import UserProfileData from '../../classes/UserProfileData';
import LoadingIcon from '../../components/LoadingIcon';
import OmniaPageOverlay from '../../components/OmniaPageOverlay';
import HelperMethods from '../../helpers/HelperMethods';
import Logger from '../../helpers/Logger';
import {
  AppDispatch,
  RootState,
} from '../../redux/store';
import { getUserProfileDataAsync } from '../../redux/userProfileDataSlice';
import ContactPage from '../websitecontent/contact/ContactPage';
import { DailyPaymentsBarChart } from './PaymentsGraph';

interface BalanceCardProps {
  cardTitle: string;
  balances: OmniaAccountBalance[] | TransactionTotal[];
}

const BalanceCard = (props: BalanceCardProps): ReactElement => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const {cardTitle, balances } = props;

  if (!balances || balances.length === 0) {
    return <></>;
  }

  return (
    <div className='signed-in-homepage-card balance'>
      <div className='signed-in-homepage-card-title-section'>
        <h4 className='signed-in-homepage-card-title'>{cardTitle}</h4>
        { balances && balances.length > 1 &&
          <div className='signed-in-homepage-card-currency-selector'>
            { balances.map((balance, index) => (
              <div
                key={index}
                className={`signed-in-homepage-card-currency-button ${index === selectedIndex ? 'selected' : ''}`}
                onClick={() => setSelectedIndex(index)}
              >
                { HelperMethods.getCurrencyFromString(balance.currency).displayName }
              </div>
            ))}                       
          </div>
        }
      </div>
      <div className='balance-card-balance-section'>
        <h2 className='balance-card-balance'>{HelperMethods.getCurrencyFromString(balances[selectedIndex].currency).symbol}{balances[selectedIndex].amount.toFixed(2)}</h2>
      </div>
    </div>
  );
}

interface DailyPaymentsBarGraphCard {
  cardTitle: string;
  dailyRevenuesPerCurrencyData: OmniaDailyRevenuesForCurrency[];
}

const DailyPaymentsBarChartCard = (props: DailyPaymentsBarGraphCard): ReactElement => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const {cardTitle, dailyRevenuesPerCurrencyData } = props;

  return (
      <div className='signed-in-homepage-card'>
        <div className='signed-in-homepage-card-title-section'>
          <h4 className='signed-in-homepage-card-title'>{cardTitle}</h4>
          { dailyRevenuesPerCurrencyData.length > 1 &&
            <div className='signed-in-homepage-card-currency-selector'>
              {
                dailyRevenuesPerCurrencyData.map((data, index) => (
                  <div
                    key={index}
                    className={`signed-in-homepage-card-currency-button graph ${index === selectedIndex ? 'selected graph' : ''}`}
                    onClick={() => setSelectedIndex(index)}
                  >
                    {data.currency.toUpperCase()}
                  </div>
                ))
              }                       
            </div>
          }
        </div>
        { dailyRevenuesPerCurrencyData && dailyRevenuesPerCurrencyData.length > 0
          ?
          <DailyPaymentsBarChart dailyRevenueData={dailyRevenuesPerCurrencyData[selectedIndex].dailyRevenueList}/>
          :
          <h4 className='no-data-graph-message'>Once you start receiving payments your payment data will be displayed here</h4>
        }
      </div>
  );
}

const SignedInHomePage = (): ReactElement => {
    const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
    const [overlayContent, setOverlayContent] = useState<ReactElement>(<></>);
    
    const reduxUserProfileData: UserProfileData | null = useSelector((state: RootState) => state.userProfileDataReducer.userProfileData);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!reduxUserProfileData) {
          Logger.log('getting accountState from SignedInHomePage');
            dispatch(getUserProfileDataAsync());
        }
    }, [reduxUserProfileData, dispatch]);

    const displayPageOverlay = (content: ReactElement): void => {
      setOverlayContent(content);
      setOverlayVisible(true);
    }
    
    if (!reduxUserProfileData) {
        return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
    }

    const dataTest = [
        {
          currency: "gbp",
          payments: [
            {
              currency: "gbp",
              amount: 11.23,
              created: 1691943877
            },
            {
              currency: "gbp",
              amount: 15.61,
              created: 1691952249
            },
            {
              currency: "gbp",
              amount: 4.36,
              created: 1691959051
            },
            {
              currency: "gbp",
              amount: 20.98,
              created: 1691964645
            },
            {
              currency: "gbp",
              amount: 10.44,
              created: 1691968910
            },
            {
              currency: "gbp",
              amount: 3.16,
              created: 1691976489
            },
            {
              currency: "gbp",
              amount: 16.72,
              created: 1691985205
            },
            {
              currency: "gbp",
              amount: 7.89,
              created: 1691989726
            },
            {
              currency: "gbp",
              amount: 18.55,
              created: 1692001318
            },
            {
              currency: "gbp",
              amount: 2.41,
              created: 1692009440
            },
            {
              currency: "gbp",
              amount: 13.07,
              created: 1692025315
            },
            {
              currency: "gbp",
              amount: 9.83,
              created: 1692033180
            },
            {
              currency: "gbp",
              amount: 5.69,
              created: 1692042130
            },
            {
              currency: "gbp",
              amount: 1.36,
              created: 1692050592
            },
            {
              currency: "gbp",
              amount: 17.24,
              created: 1692058756
            },
            {
              currency: "gbp",
              amount: 6.92,
              created: 1692066389
            },
            {
              currency: "gbp",
              amount: 12.78,
              created: 1692074787
            },
            {
              currency: "gbp",
              amount: 19.41,
              created: 1692082950
            },
            {
              currency: "gbp",
              amount: 8.15,
              created: 1692090840
            },
            {
              currency: "gbp",
              amount: 14.33,
              created: 1692099242
            },
            {
              currency: "gbp",
              amount: 20.67,
              created: 1692107854
            },
            {
              currency: "gbp",
              amount: 11.89,
              created: 1692116105
            },
            {
              currency: "gbp",
              amount: 3.72,
              created: 1692124711
            },
            {
              currency: "gbp",
              amount: 16.46,
              created: 1692133180
            },
            {
              currency: "gbp",
              amount: 7.58,
              created: 1692141915
            },
            {
              currency: "gbp",
              amount: 19.23,
              created: 1692150402
            },
            {
              currency: "gbp",
              amount: 2.84,
              created: 1692158942
            },
            {
              currency: "gbp",
              amount: 13.51,
              created: 1692167625
            },
            {
              currency: "gbp",
              amount: 9.67,
              created: 1692176155
            },
            {
              currency: "gbp",
              amount: 5.35,
              created: 1692184836
            },
            {
              currency: "gbp",
              amount: 18.09,
              created: 1692193339
            },
            {
              currency: "gbp",
              amount: 6.76,
              created: 1692202060
            },
            {
              currency: "gbp",
              amount: 12.42,
              created: 1692210744
            },
            {
              currency: "gbp",
              amount: 19.85,
              created: 1692219387
            },
            {
              currency: "gbp",
              amount: 8.31,
              created: 1692228169
            },
            {
              currency: "gbp",
              amount: 14.97,
              created: 1692236896
            },
            {
              currency: "gbp",
              amount: 20.13,
              created: 1692245577
            },
            {
              currency: "gbp",
              amount: 11.55,
              created: 1692254309
            },
            {
              currency: "gbp",
              amount: 3.88,
              created: 1692263099
            },
            {
              currency: "gbp",
              amount: 16.2,
              created: 1692271831
            },
            {
              currency: "gbp",
              amount: 7.74,
              created: 1692280613
            },
            {
              currency: "gbp",
              amount: 19.59,
              created: 1692289345
            },
            {
              currency: "gbp",
              amount: 2.68,
              created: 1692298127
            },
            {
              currency: "gbp",
              amount: 13.35,
              created: 1692306859
            },
            {
              currency: "gbp",
              amount: 9.51,
              created: 1692315641
            },
            {
              currency: "gbp",
              amount: 5.19,
              created: 1692324373
            },
            {
              currency: "gbp",
              amount: 17.93,
              created: 1692333155
            },
            {
              currency: "gbp",
              amount: 6.6,
              created: 1692341887
            },
            {
              currency: "gbp",
              amount: 12.26,
              created: 1692350669
            },
            {
              currency: "gbp",
              amount: 19.69,
              created: 1692359401
            }
          ]
        },
        {
          currency: "usd",
          payments: [
            {
              currency: "usd",
              amount: 18.87,
              created: 1691947034
            },
            {
              currency: "usd",
              amount: 5.52,
              created: 1691955673
            },
            {
              currency: "usd",
              amount: 14.18,
              created: 1691972565
            },
            {
              currency: "usd",
              amount: 1.72,
              created: 1691980847
            },
            {
              currency: "usd",
              amount: 9.29,
              created: 1691993707
            },
            {
              currency: "usd",
              amount: 20.34,
              created: 1692005559
            },
            {
              currency: "usd",
              amount: 11.6,
              created: 1692013441
            },
            {
              currency: "usd",
              amount: 3.95,
              created: 1692017232
            },
            {
              currency: "usd",
              amount: 16.81,
              created: 1692029501
            },
            {
              currency: "usd",
              amount: 8.07,
              created: 1692037654
            },
            {
              currency: "usd",
              amount: 19.43,
              created: 1692046674
            },
            {
              currency: "usd",
              amount: 2.59,
              created: 1692054533
            },
            {
              currency: "usd",
              amount: 13.25,
              created: 1692062675
            },
            {
              currency: "usd",
              amount: 9.91,
              created: 1692070586
            },
            {
              currency: "usd",
              amount: 5.57,
              created: 1692078866
            },
            {
              currency: "usd",
              amount: 18.23,
              created: 1692086895
            },
            {
              currency: "usd",
              amount: 6.88,
              created: 1692095047
            },
            {
              currency: "usd",
              amount: 12.54,
              created: 1692103548
            },
            {
              currency: "usd",
              amount: 19.97,
              created: 1692111958
            },
            {
              currency: "usd",
              amount: 8.39,
              created: 1692120408
            },
            {
              currency: "usd",
              amount: 15.05,
              created: 1692128909
            },
            {
              currency: "usd",
              amount: 20.81,
              created: 1692137347
            },
            {
              currency: "usd",
              amount: 11.71,
              created: 1692145748
            },
            {
              currency: "usd",
              amount: 4.03,
              created: 1692154596
            },
            {
              currency: "usd",
              amount: 16.69,
              created: 1692163770
            },
            {
              currency: "usd",
              amount: 7.95,
              created: 1692171390
            },
            {
              currency: "usd",
              amount: 19.31,
              created: 1692180514
            },
            {
              currency: "usd",
              amount: 2.75,
              created: 1692189085
            },
            {
              currency: "usd",
              amount: 13.39,
              created: 1692197700
            },
            {
              currency: "usd",
              amount: 9.75,
              created: 1692206402
            },
            {
              currency: "usd",
              amount: 5.43,
              created: 1692215023
            },
            {
              currency: "usd",
              amount: 18.37,
              created: 1692223644
            },
            {
              currency: "usd",
              amount: 7.02,
              created: 1692232265
            },
            {
              currency: "usd",
              amount: 12.68,
              created: 1692240886
            },
            {
              currency: "usd",
              amount: 20.11,
              created: 1692249507
            },
            {
              currency: "usd",
              amount: 8.47,
              created: 1692258128
            },
            {
              currency: "usd",
              amount: 15.13,
              created: 1692266749
            },
            {
              currency: "usd",
              amount: 20.89,
              created: 1692275370
            },
            {
              currency: "usd",
              amount: 11.79,
              created: 1692283991
            },
            {
              currency: "usd",
              amount: 4.11,
              created: 1692292612
            },
            {
              currency: "usd",
              amount: 16.77,
              created: 1692301233
            },
            {
              currency: "usd",
              amount: 8.03,
              created: 1692309854
            },
            {
              currency: "usd",
              amount: 19.39,
              created: 1692318475
            },
            {
              currency: "usd",
              amount: 2.83,
              created: 1692327096
            },
            {
              currency: "usd",
              amount: 13.47,
              created: 1692335717
            },
            {
              currency: "usd",
              amount: 9.83,
              created: 1692344338
            },
            {
              currency: "usd",
              amount: 5.51,
              created: 1692352959
            },
            {
              currency: "usd",
              amount: 18.45,
              created: 1692361580
            }
          ]
        }
      ];

  const dailyRevenueList = [
    {
    currency: 'gbp',
    dailyRevenueList:  [
      {
        currency: 'gbp',
        amount: 108.21,
        date: 1725321600
      },
      {
        currency: 'gbp',
        amount: 105.87,
        date: 1725408000
      },
      {
        currency: 'gbp',
        amount: 97.43,
        date: 1725494400
      },
      {
        currency: 'gbp',
        amount: 56.75,
        date: 1725580800
      },
      {
        currency: 'gbp',
        amount: 69.12,
        date: 1725667200
      },
      {
        currency: 'gbp',
        amount: 84.56,
        date: 1725753600
      },
      {
        currency: 'gbp',
        amount: 67.89,
        date: 1725840000
      },
      {
        currency: 'gbp',
        amount: 45.43,
        date: 1725926400
      },
      {
        currency: 'gbp',
        amount: 58.76,
        date: 1726012800
      },
      {
        currency: 'gbp',
        amount: 6.21,
        date: 1726099200
      },
      {
        currency: 'gbp',
        amount: 19.54,
        date: 1726185600
      },
      {
        currency: 'gbp',
        amount: 75.87,
        date: 1726272000
      },
      {
        currency: 'gbp',
        amount: 89.43,
        date: 1726358400
      },
      {
        currency: 'gbp',
        amount: 96.75,
        date: 1726444800
      },
      {
        currency: 'gbp',
        amount: 58.12,
        date: 1726531200
      },
      {
        currency: 'gbp',
        amount: 55.43,
        date: 1726617600
      },
      {
        currency: 'gbp',
        amount: 57.89,
        date: 1726704000
      },
      {
        currency: 'gbp',
        amount: 56.21,
        date: 1726790400
      },
      {
        currency: 'gbp',
        amount: 68.76,
        date: 1726876800
      },
      {
        currency: 'gbp',
        amount: 35.87,
        date: 1726963200
      },
      {
        currency: 'gbp',
        amount: 107.54,
        date: 1727049600
      },
      {
        currency: 'gbp',
        amount: 99.43,
        date: 1727136000
      },
      {
        currency: 'gbp',
        amount: 46.75,
        date: 1727222400
      },
      {
        currency: 'gbp',
        amount: 68.12,
        date: 1727308800
      },
      {
        currency: 'gbp',
        amount: 45.43,
        date: 1727395200
      },
      {
        currency: 'gbp',
        amount: 17.89,
        date: 1727481600
      },
      {
        currency: 'gbp',
        amount: 96.21,
        date: 1727568000
      },
      {
        currency: 'gbp',
        amount: 88.76,
        date: 1727654400
      },
      {
        currency: 'gbp',
        amount: 55.87,
        date: 1727740800
      }
    ]
    },
    {
      currency: 'usd',
      dailyRevenueList:  [
        {
          currency: 'gbp',
          amount: 1,
          date: 1725321600
        },
        {
          currency: 'gbp',
          amount: 5.84,
          date: 1725408000
        },
        {
          currency: 'gbp',
          amount: 2.43,
          date: 1725494400
        },
        {
          currency: 'gbp',
          amount: 6.75,
          date: 1725580800
        },
        {
          currency: 'gbp',
          amount: 9.12,
          date: 1725667200
        },
        {
          currency: 'gbp',
          amount: 4.545,
          date: 1725753600
        },
        {
          currency: 'gbp',
          amount: 7.89,
          date: 1725840000
        },
        {
          currency: 'gbp',
          amount: 7.43,
          date: 1725926400
        },
        {
          currency: 'gbp',
          amount: 8.76,
          date: 1726012800
        },
        {
          currency: 'gbp',
          amount: 6.21,
          date: 1726099200
        },
        {
          currency: 'gbp',
          amount: 7.54,
          date: 1726185600
        },
        {
          currency: 'gbp',
          amount: 5.87,
          date: 1726272000
        },
        {
          currency: 'gbp',
          amount: 1.43,
          date: 1726358400
        },
        {
          currency: 'gbp',
          amount: 1.75,
          date: 1726444800
        },
        {
          currency: 'gbp',
          amount: 1.12,
          date: 1726531200
        },
        {
          currency: 'gbp',
          amount: 1.43,
          date: 1726617600
        },
        {
          currency: 'gbp',
          amount: 1.89,
          date: 1726704000
        },
        {
          currency: 'gbp',
          amount: 1.21,
          date: 1726790400
        },
        {
          currency: 'gbp',
          amount: .76,
          date: 1726876800
        },
        {
          currency: 'gbp',
          amount: 5.87,
          date: 1726963200
        },
        {
          currency: 'gbp',
          amount: 7.54,
          date: 1727049600
        },
        {
          currency: 'gbp',
          amount: 9.43,
          date: 1727136000
        },
        {
          currency: 'gbp',
          amount: 6.75,
          date: 1727222400
        },
        {
          currency: 'gbp',
          amount: 8.12,
          date: 1727308800
        },
        {
          currency: 'gbp',
          amount: 5.43,
          date: 1727395200
        },
        {
          currency: 'gbp',
          amount: 7.89,
          date: 1727481600
        },
        {
          currency: 'gbp',
          amount: 6.21,
          date: 1727568000
        },
        {
          currency: 'gbp',
          amount: 8.76,
          date: 1727654400
        },
        {
          currency: 'gbp',
          amount: 5.87,
          date: 1727740800
        }
      ]
      }
  ];

    return (
        <>
            <BalanceCard 
                cardTitle='Lifetime Earnings'
                balances={reduxUserProfileData.receivedAmountPerCurrency}
            />
            <BalanceCard 
                cardTitle='Current Balance'
                balances={reduxUserProfileData.accountBalancePerCurrency}
            />
            <DailyPaymentsBarChartCard
                cardTitle='Daily Revenue (last 30 days)'
                dailyRevenuesPerCurrencyData={
                  //dailyRevenueList
                  reduxUserProfileData.dailyRevenuesPerCurrencyData
                }
            />
            <div className='signed-in-homepage-card'>
              <div className='signed-in-homepage-card-title-section'>
                <h4 className='signed-in-homepage-card-title'>Any suggestions?</h4>
              </div>
              <div className='signed-in-homepage-card-content'>
                <p>We want to make Omnia the best it can be, and do that we need your help.</p>
                <p>If you have any features you'd like to see please let us know. As a small start-up we are able to review <b>every</b> customer request and will make improvements wherever possible to improve your Omnia experience.</p>
              <div
                className='signed-in-homepage-suggestion-button'
                onClick={() => displayPageOverlay(<ContactPage/>)}
              >Leave suggestion</div>
              </div>
            </div>
            { overlayVisible &&
                <OmniaPageOverlay
                    content={overlayContent}
                    onClose={() => setOverlayVisible(false)}
                />
            }
        </>
    );
}

export default SignedInHomePage;
