import './textlink.scss';

interface LinkProps {
    text: string;
    href: string;
    colour?: 'orange' | 'black' | 'white' | 'grey-mid';
}

const TextLink = (props: LinkProps) => {
    const { text, href, colour } = props;

    return (
        <a className={`text ${colour ? colour : ''}`} href={href} target='_blank' rel='noopener noreferrer'>{text}</a>
    );
}

export default TextLink;
