import './settings.scss';

import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { FaChevronRight } from 'react-icons/fa6';
import { IoExit } from 'react-icons/io5';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router';

import ApiResponse from '../../classes/ApiResponse';
import UpdateProfileRequest from '../../classes/UpdateProfileRequest';
import UpdateProfileResponse from '../../classes/UpdateProfileResponse';
import UserProfileData from '../../classes/UserProfileData';
import LoadingIcon from '../../components/LoadingIcon';
import OmniaPageOverlay from '../../components/OmniaPageOverlay';
import Constants from '../../configuration/constants';
import Logger from '../../helpers/Logger';
import {
  AppDispatch,
  RootState,
} from '../../redux/store';
import {
  getUserProfileDataAsync,
  updateUserProfileData,
} from '../../redux/userProfileDataSlice';
import AboutOmniaPage from '../websitecontent/aboutomnia/AboutOmniaPage';
import ContactPage from '../websitecontent/contact/ContactPage';
import FAQsPage from '../websitecontent/faqs/FAQsPage';
import AcceptableUsePolicyPage
  from '../websitecontent/legal/AcceptableUsePolicyPage';
import CookiePolicyPage from '../websitecontent/legal/CookiePolicyPage';
import DisclaimerPolicyPage from '../websitecontent/legal/DisclaimerPolicyPage';
import PrivacyPolicyPage from '../websitecontent/legal/PrivacyPolicyPage';
import RefundPolicyPage from '../websitecontent/legal/RefundPolicyPage';
import TaxOnTipsPage from '../websitecontent/taxontips/TaxOnTipsPage';
import TippingTipsPage from '../websitecontent/tippingtips/TippingTipsPage';

interface SectionItem {
    name: string;
    description?: string;
    icon: ReactElement;
    onClick?: () => void;
}

interface SettingsSectionProps {
    title: string;
    sectionItems: SectionItem[];
}

const SettingsSection = (props: SettingsSectionProps): ReactElement => {
    const { title, sectionItems } = props;

    return (
        <div className='settings-section'>
            <h2 className='settings-section-title'>{title}</h2>
            { sectionItems.map((item: SectionItem, index: number) => (
                <div 
                    key={index}
                    className={`settings-section-item ${index === sectionItems.length-1 ? 'last-item' : ''} ${item.onClick ? 'shows-content' : ''}`}
                    onClick={item.onClick}
                >
                    <div className='settings-section-item-text'>
                        <p className='settings-section-item-title'>{item.name}</p>
                        { item.description &&
                            <p className='settings-section-item-description'>{item.description}</p>
                        }
                    </div>
                    { item.icon &&
                        item.icon
                    }
                </div>
            ))}
        </div>
    );
}

const Settings = (): ReactElement => {
    const [profileHiddenUpdating, setProfileHiddenUpdating] = useState<boolean>(false);
    const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
    const [overlayContent, setOverlayContent] = useState<ReactElement>(<></>);


    const reduxUserProfileData: UserProfileData | null = useSelector((state: RootState) => state.userProfileDataReducer.userProfileData);
    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();

    useEffect(() => {
        if (!reduxUserProfileData) {
            Logger.log('getting accountState from Settings');
            dispatch(getUserProfileDataAsync());
        }
    }, [reduxUserProfileData, dispatch]);

    useEffect(() => {
        // Toggle the no-scroll class on the body element when the overlay is visible
        if (overlayVisible) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Clean up by removing the class when the component unmounts or overlay becomes invisible
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [overlayVisible]);

    const setProfileHidden = async (): Promise<void> => {
        setProfileHiddenUpdating(true);
        if (reduxUserProfileData) {
            const updatedUserProfileData: UserProfileData = {
                ...reduxUserProfileData,
                profileHidden: !reduxUserProfileData.profileHidden
            }
            await ApiResponse.getApiResponse(
                Constants.updateProfileEndpoint,
                new UpdateProfileRequest(updatedUserProfileData),
                UpdateProfileResponse,
                null,
                null,
                true
            ).then(response => {
                JSON.stringify(response);
                if (!response || (response instanceof AxiosError && response.request.status === 401)) {
                    navigate(Constants.authenticatePagePath);
                } else if (response instanceof AxiosError || !response.profileUpdateSuccess) {
                    navigate(Constants.homePagePath);
                } else {
                    // Using Redux Dispatch to update the updatedUserProfileData in the Redux store
                    dispatch(updateUserProfileData(updatedUserProfileData))
                }
            });
        }
        setProfileHiddenUpdating(false);
    }

    const displayPageOverlay = (content: ReactElement): void => {
        setOverlayContent(content);
        setOverlayVisible(true);
    }
    
    const signOut = (): void => {
        Cookies.remove(Constants.jwtTokenName);
        window.location.reload();
    }

    if (!reduxUserProfileData) {
        return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
    }
    
    return (
        <div className='settings-page-wrapper'>
            <SettingsSection
                title='Profile Settings'
                sectionItems={[
                    {
                        name: 'Profile Hidden',
                        description: 'When hidden your Payment Page won\'t be shown when your QR code is scanned',
                        icon: <input
                            className='settings-section-item-icon checkbox'
                            type='checkbox'
                            disabled={profileHiddenUpdating}
                            checked={reduxUserProfileData.profileHidden}
                            onChange={setProfileHidden}
                            />
                    }
                ]}
            />
            <SettingsSection
                title='About'
                sectionItems={[
                    {
                        name: 'Omnia',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<AboutOmniaPage/>)
                    },
                    {
                        name: 'FAQs',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<FAQsPage/>)
                    },
                    {
                        name: 'Contact',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<ContactPage/>)
                    }
                ]}
            />
            <SettingsSection
                title='Info'
                sectionItems={[
                    {
                        name: 'Tipping Tips',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<TippingTipsPage/>)
                    },
                    {
                        name: 'Tax on Tips',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<TaxOnTipsPage/>)
                    }
                ]}
            />
            <SettingsSection
                title='Legal'
                sectionItems={[
                    {
                        name: 'Terms and Conditions',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<div>hello</div>)
                    },
                    {
                        name: 'Privacy Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<PrivacyPolicyPage/>)    
                    },
                    {
                        name: 'Cookie Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<CookiePolicyPage/>)    
                    },
                    {
                        name: 'Acceptable Use Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<AcceptableUsePolicyPage/>)    
                    },
                    {
                        name: 'Disclaimer Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<DisclaimerPolicyPage/>)    
                    },
                    {
                        name: 'Refund Policy',
                        icon: <FaChevronRight className='settings-section-item-icon'/>,
                        onClick: () => displayPageOverlay(<RefundPolicyPage/>) 
                    }
                ]}
            />
            <SettingsSection title='Account Actions'
                sectionItems={[
                    {
                        name: 'Sign-out',
                        icon: <IoExit className='settings-section-item-icon'/>,
                        onClick: signOut 
                    }
                ]}
            />
            { overlayVisible &&
                <OmniaPageOverlay
                    content={overlayContent}
                    onClose={() => setOverlayVisible(false)}
                />
            }
        </div>
    );
}

export default Settings;
