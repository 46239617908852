import './base64profileimageedit.scss';

import {
  ChangeEvent,
  ReactElement,
} from 'react';

import Resizer from 'react-image-file-resizer';

import UserProfileData from '../../../classes/UserProfileData';
import HelperMethods from '../../../helpers/HelperMethods';
import Logger from '../../../helpers/Logger';
import { EditProfileModalBodyProps } from './types';

const Base64ProfileImageEdit = (props: EditProfileModalBodyProps): ReactElement => {
    const { updatedUserProfileData, setUpdatedUserProfileData, setHasMadeEdit } = props;

    const resizeImage = (blob: Blob, quality: number, dimension: number, maxSizeInKB: number, targetDimension: number) => {
        Resizer.imageFileResizer(
            blob,
            dimension,
            dimension,
            'JPEG',
            quality,
            0,
            (uri) => {
                if (typeof uri === 'string') {
                    const base64Length = uri.length;
                    const padding = (uri.charAt(base64Length - 2) === '=') ? 2 : (uri.charAt(base64Length - 1) === '=') ? 1 : 0;
                    const fileSize = (base64Length * 3 / 4) - padding;
                    Logger.log(`Base64 length: ${base64Length}, File size: ${fileSize / 1024} KB, Quality: ${quality}`);
                    
                    if (fileSize > maxSizeInKB * 1024) {
                        if (quality > 50) {
                            // Large reduction step for large images
                            resizeImage(blob, quality - 20, dimension, maxSizeInKB, targetDimension);
                        } else if (quality > 20) {
                            // Smaller reduction step as we approach the limit
                            resizeImage(blob, quality - 10, dimension, maxSizeInKB, targetDimension);
                        } else if (quality > 5) {
                            // Minimal reduction as we get very close to the target size
                            resizeImage(blob, quality - 5, dimension, maxSizeInKB, targetDimension);
                        } else if (quality > 1) {
                            resizeImage(blob, quality - 1, dimension, maxSizeInKB, targetDimension);
                        } else if (targetDimension> 100) {
                            resizeImage(blob, quality, maxSizeInKB, dimension, targetDimension - 50);
                        } else {
                            console.error('Cannot resize the image below the desired size');
                        }
                    } else {
                        // Successfully reduced the image
                        setUpdatedUserProfileData(prevState => {
                            return HelperMethods.cloneWithUpdates(UserProfileData, prevState, { base64ProfileImage: uri });
                        });
                    }
                }
            },
            'base64',
            maxSizeInKB // Adjust the size limit here
        );
    }

    const handleProfileImageUpload = (event: ChangeEvent<HTMLInputElement>): void => {
        setHasMadeEdit(true);
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const img = new Image();
                img.onload = function () {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    if (ctx) {
                        const minDimension = Math.min(img.width, img.height);
                        canvas.width = minDimension;
                        canvas.height = minDimension;
                        ctx.drawImage(img, (img.width - minDimension) / 2, (img.height - minDimension) / 2, minDimension, minDimension, 0, 0, minDimension, minDimension);

                        canvas.toBlob(blob => {
                            if (blob) {
                                // Start with a lower quality if the image is very large (e.g., 2MB+)
                                let initialQuality = file.size > 1024 * 1024 ? 80 : 90; // Starting quality
                                resizeImage(blob, initialQuality, minDimension, 50, minDimension); // 50 KB target
                            }
                        }, 'image/jpeg');
                    } else {
                        console.error('Canvas context is null');
                    }
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <div className='image-upload-section'>
                <div className='image-inner-div'>
                    <div className='profile-image-preview'>
                        <img className='profile-image' src={updatedUserProfileData.base64ProfileImage} alt='Profile'/>
                    </div>
                    <label className='profile-image-upload-button'>
                        Upload
                        <input
                            type='file'
                            accept='image/*'
                            onChange={(event) => handleProfileImageUpload(event)}
                            hidden
                        />
                    </label>
                </div>
            </div>
        </>
    );
}

export default Base64ProfileImageEdit;
