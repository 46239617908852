import '../websitecontentstyle.scss';

import { ReactElement } from 'react';

import ContactUs from '../../../components/ContactUs';
import PageContent from '../../../components/PageContent';

const RefundPolicyPage = (): ReactElement => {
    return (
        <PageContent alignItems='left' fullWidth>
            <h1>Refund Policy</h1>
            
            <p>As the Website offers non-tangible, irrevocable goods we do not provide refunds after a transaction is completed, which you acknowledge prior to completing any transaction on the Website. Please make sure that you have read the service description before completing a transaction.</p>
            
            <h2>Contact us</h2>
            <p>If you have any questions or concerns related to this policy please <ContactUs/>.</p>
            
            <p>This policy was last updated November 6, 2024.</p>
        </PageContent>
    );
}

export default RefundPolicyPage;
