import './pagedefault.scss';

import React, {
  ReactElement,
  ReactNode,
  useEffect,
} from 'react';

import { useLocation } from 'react-router';

interface PageDefaultProps {
    children: ReactNode
    backgroundColor?: 'off-white' | 'black';
}
const PageDefault = (props: PageDefaultProps): ReactElement => {
    const { children, backgroundColor } = props;
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
      
    return (
        <div className={`page-default ${backgroundColor}`}>
            {children}
        </div>
    );
};
  
export default PageDefault;
