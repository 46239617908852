export type CreateAccountPageFormData = {
    businessType: string;
    industryType: string;
    legalName: string;
    displayName: string;
    username: string;
    phoneNumber: string;
    currency: string;
    country: string;
    address: Address;
    url: string;
    dob: string;
}

export type Address = {
    line1: string | null;
    line2: string | null;
    postalCode: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export class BusinessType {
    public static readonly COMPANY = new BusinessType('COMPANY', 'Company');
    public static readonly INDIVIDUAL = new BusinessType('INDIVIDUAL', 'Individual');
    public static readonly NON_PROFIT = new BusinessType('NON_PROFIT', 'Non-profit');

    private constructor(
        public readonly value: string,
        public readonly displayName: string
    ) {}

    public static getAllBusinessTypes(): BusinessType[] {
        return Object.values(BusinessType).filter(value => value instanceof BusinessType);
    }
}

export class IndustryType {
    public static readonly BANDS_ORCHESTRAS = new IndustryType('BANDS_ORCHESTRAS', 'Bands, Orchestras');
    public static readonly BAKERIES = new IndustryType('BAKERIES', 'Bakeries');
    public static readonly BARBER_AND_BEAUTY_SHOPS = new IndustryType('BARBER_AND_BEAUTY_SHOPS', 'Barber and Beauty Shops');
    public static readonly CATERERS = new IndustryType('CATERERS', 'Caterers');
    public static readonly CAR_WASHES = new IndustryType('CAR_WASHES', 'Car Washes');
    public static readonly COUNTRY_CLUBS = new IndustryType('COUNTRY_CLUBS', 'Country Clubs');
    public static readonly COMMUTER_TRANSPORT_AND_FERRIES = new IndustryType('COMMUTER_TRANSPORT_AND_FERRIES', 'Commuter Transport, Ferries');
    public static readonly CLEANING_AND_MAINTENANCE = new IndustryType('CLEANING_AND_MAINTENANCE', 'Cleaning and Maintenance');
    public static readonly DANCE_HALL_STUDIOS_SCHOOLS = new IndustryType('DANCE_HALL_STUDIOS_SCHOOLS', 'Dance Hall, Studios, Schools');
    public static readonly DRINKING_PLACES = new IndustryType('DRINKING_PLACES', 'Drinking Places');
    public static readonly EDUCATIONAL_SERVICES = new IndustryType('EDUCATIONAL_SERVICES', 'Educational Services');
    public static readonly EATING_PLACES_RESTAURANTS = new IndustryType('EATING_PLACES_RESTAURANTS', 'Eating Places, Restaurants');
    public static readonly FAST_FOOD_RESTAURANTS = new IndustryType('FAST_FOOD_RESTAURANTS', 'Fast Food Restaurants');
    public static readonly GENERAL_SERVICES = new IndustryType('GENERAL_SERVICES', 'General Services');
    public static readonly GOLF_COURSES_PUBLIC = new IndustryType('GOLF_COURSES_PUBLIC', 'Golf Courses - Public');
    public static readonly HEALTH_AND_BEAUTY_SPAS = new IndustryType('HEALTH_AND_BEAUTY_SPAS', 'Health and Beauty Spas');
    public static readonly HOTELS_MOTELS_AND_RESORTS = new IndustryType('HOTELS_MOTELS_AND_RESORTS', 'Hotels, Motels and Resorts');
    public static readonly MISCELLANEOUS_BUSINESS_SERVICES = new IndustryType('MISCELLANEOUS_BUSINESS_SERVICES', 'Miscellaneous Business Services');
    public static readonly MISCELLANEOUS_FOOD_STORES = new IndustryType('MISCELLANEOUS_FOOD_STORES', 'Miscellaneous Food Stores - Convenience Stores and Specialty Markets');
    public static readonly MISCELLANEOUS_GENERAL_SERVICES = new IndustryType('MISCELLANEOUS_GENERAL_SERVICES', 'Miscellaneous General Services');
    public static readonly NON_DURABLE_GOODS = new IndustryType('NON_DURABLE_GOODS', 'Nondurable Goods (Not Elsewhere Classified)');
    public static readonly RELIGIOUS_GOODS_STORES = new IndustryType('RELIGIOUS_GOODS_STORES', 'Religious Goods Stores');
    public static readonly SPORTS_CLUBS_FIELDS = new IndustryType('SPORTS_CLUBS_FIELDS', 'Sports Clubs/Fields');
    public static readonly TAXI_CAB_LIMOUSINES = new IndustryType('TAXI_CAB_LIMOUSINES', 'Taxicabs/Limousines');
    public static readonly TRAVEL_AGENCIES_TOUR_OPERATORS = new IndustryType('TRAVEL_AGENCIES_TOUR_OPERATORS', 'Travel Agencies, Tour Operators');
    public static readonly TRANSPORTATION_SERVICES = new IndustryType('TRANSPORTATION_SERVICES', 'Transportation Services (Not Elsewhere Classified)');

    private constructor(
        public readonly value: string,
        public readonly displayName: string
    ) {}

    public static getAllIndustryTypes(): IndustryType[] {
        return Object.values(IndustryType).filter(value => value instanceof IndustryType);
    }
}

export class Country {
    public static readonly UNITED_KINGDOM = new Country('GB', 'United Kingdom');

    private constructor(
        public readonly value: string,
        public readonly displayName: string
    ) {}

    public static getAllCountries(): Country[] {
        return Object.values(Country).filter(value => value instanceof Country);
    }
}
