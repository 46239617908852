import './index';

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import NotSignedInNavbar from './components/navbar/NotSignedInNavbar';
import PageDefault from './components/PageDefault';
import PageFooter from './components/PageFooter';
import Constants from './configuration/constants';
import AuthenticatePage from './pages/authenticate/AuthenticatePage';
import ConnectedAccountPage
  from './pages/connectedaccount/ConnectedAccountPage';
import CreateAccountPage from './pages/createaccount/CreateAccountPage';
import HomePage from './pages/homepage/HomePage';
import PaymentPage from './pages/payment/PaymentPage';
import PaymentConfirmationPage
  from './pages/paymentconfirmation/PaymentConfirmationPage';
import AboutOmniaPage from './pages/websitecontent/aboutomnia/AboutOmniaPage';
import ContactPage from './pages/websitecontent/contact/ContactPage';
import FAQsPage from './pages/websitecontent/faqs/FAQsPage';
import AcceptableUsePolicyPage
  from './pages/websitecontent/legal/AcceptableUsePolicyPage';
import CookiePolicyPage from './pages/websitecontent/legal/CookiePolicyPage';
import DisclaimerPolicyPage
  from './pages/websitecontent/legal/DisclaimerPolicyPage';
import PrivacyPolicyPage from './pages/websitecontent/legal/PrivacyPolicyPage';
import RefundPolicyPage from './pages/websitecontent/legal/RefundPolicyPage';
import TermsAndConditionsPolicyPage
  from './pages/websitecontent/legal/TermsAndConditionsPolicyPage';
import TaxOnTipsPage from './pages/websitecontent/taxontips/TaxOnTipsPage';
import TippingTipsPage
  from './pages/websitecontent/tippingtips/TippingTipsPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path={Constants.homePagePath} element={<HomePage/>}/>
        <Route path={`${Constants.sendPagePath}/:username`} element={<PageDefault><PaymentPage/></PageDefault>}/>
        <Route path={`${Constants.paymentConfirmationPagePath}/:username`} element={<PageDefault><PaymentConfirmationPage/></PageDefault>}/>
        <Route path={Constants.authenticatePagePath} element={<PageDefault><AuthenticatePage/></PageDefault>}/>
        <Route path={Constants.createAccountPagePath} element={<PageDefault><CreateAccountPage/></PageDefault>}/>
        <Route path={Constants.connectedAccountPagePath} element={<PageDefault><ConnectedAccountPage/></PageDefault>}/>
        <Route path={Constants.taxOnTipsPagePath} element={<PageDefault><NotSignedInNavbar/><TaxOnTipsPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.tippingTipsPagePath} element={<PageDefault><NotSignedInNavbar/><TippingTipsPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.aboutPagePath} element={<PageDefault><NotSignedInNavbar/><AboutOmniaPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.contactPagePath} element={<PageDefault><NotSignedInNavbar/><ContactPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.faqsPagePath} element={<PageDefault><NotSignedInNavbar/><FAQsPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.privacyPolicyPagePath} element={<PageDefault><NotSignedInNavbar/><PrivacyPolicyPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.cookiePolicyPagePath} element={<PageDefault><NotSignedInNavbar/><CookiePolicyPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.acceptableUsePolicyPagePath} element={<PageDefault><NotSignedInNavbar/><AcceptableUsePolicyPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.refundPolicyPagePath} element={<PageDefault><NotSignedInNavbar/><RefundPolicyPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.disclaimerPolicyPagePath} element={<PageDefault><NotSignedInNavbar/><DisclaimerPolicyPage/><PageFooter/></PageDefault>}/>
        <Route path={Constants.termsAndConditionsPolicyPagePath} element={<PageDefault><NotSignedInNavbar/><TermsAndConditionsPolicyPage/><PageFooter/></PageDefault>}/>

        {/* Default route that redirects to the homepage */}
        <Route path='*' element={<Navigate to={Constants.homePagePath}/>}/>
      </Routes>
    </Router>
  );
}

export default App;
