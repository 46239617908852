import './forminputs.scss';

import {
  Control,
  Controller,
} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

interface FormInputSelectProps {
    name: string;
    label: string;
    control: Control<any, any>;
    helperText: string;
    errorText: string | undefined;
    items: any[];
}

export const FormInputSelect = (props: FormInputSelectProps) => {
    const { name, label, control, helperText, errorText, items } = props;

    return (
        <div className='form-item'>
            <label className={'form-item-title'}>{label}</label>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <select
                        className='form-item-text-input'
                        {...field}
                        onChange={field.onChange}
                        value={field.value}
                    >
                        { items.map((option: any, index: number) => (
                            <option key={index} value={option.value}>
                                {option.displayName}
                            </option>
                        ))}
                    </select>
                )}
            />
            <p className={'form-item-label' + (errorText ? ' error' : '')}>
                { errorText ?? helperText }
            </p>
        </div>
    );
};

interface FormInputTextProps {
    name: string;
    label: string;
    placeholder: string;
    type: string;
    control: Control<any, any>;
    helperText: string;
    errorText: string | undefined;
    required: boolean;
    rules: any;
}

export const FormInputText = (props: FormInputTextProps) => {
    const { name, label, placeholder, type, control, helperText, errorText, required, rules } = props;

    return (
        <div className='form-item'>
            <label className='form-item-title'>{`${label} ${required ? '*' : ''}`}</label>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <input
                        {...field}
                        className={`form-item-text-input ${errorText ? 'error' : ''}`}
                        type={type}
                        placeholder={placeholder}
                    />
                )}
            />
            <p className={`form-item-label ${errorText ? 'error' : ''}`}>
                { errorText ? errorText : `${helperText} ${required ? '(required)' : ''}`}
            </p>
        </div>
    );
};

interface FormInputTextAreaProps {
    name: string;
    label: string;
    placeholder: string;
    control: Control<any, any>;
    helperText: string;
    errorText: string | undefined;
    required: boolean;
    rules: any;
}

export const FormInputTextArea = (props: FormInputTextAreaProps) => {
    const { name, label, placeholder, control, helperText, errorText, required, rules } = props;

    return (
        <div className='form-item'>
            <label className='form-item-title'>{`${label} ${required ? '*' : ''}`}</label>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <textarea
                        {...field}
                        className={`form-item-text-input text-area ${errorText ? 'error' : ''}`}
                        placeholder={placeholder}
                    />
                )}
            />
            <p className={`form-item-label ${errorText ? 'error' : ''}`}>
                { errorText ? errorText : `${helperText} ${required ? '(required)' : ''}`}
            </p>
        </div>
    );
};

interface FormInputDateProps {
    name: string;
    control: Control<any, any>;
    label: string;
    helperText: string;
    errorText: string | undefined;
    required: boolean;
    rules: any;
}

export const FormInputDate = (props: FormInputDateProps) => {
    const { name, label, control, helperText, errorText, required, rules } = props;

    return (
        <div className='form-item'>
            <label className='form-item-title'>{`${label} ${required ? '*' : ''}`}</label>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <input
                        {...field}
                        type='date'
                        className={`form-item-text-input ${errorText ? 'error' : ''}`}
                    />
                )}
            />
            <p className={`form-item-label ${errorText ? 'error' : ''}`}>
                { errorText ? errorText : `${helperText} ${required ? '(required)' : ''}`}
            </p>
        </div>
    );
};

interface FormInputPhoneProps {
    name: string;
    control: Control<any, any>;
    label: string;
    helperText: string;
    errorText: string | undefined;
    required: boolean;
    rules: any;
}

export const FormInputPhone = (props: FormInputPhoneProps) => {
    const { name, label, control, helperText, errorText, required, rules } = props;

    return (
        <div className='form-item'>
            <label className='form-item-title'>{`${label} ${required ? '*' : ''}`}</label>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <PhoneInput
                        country={'gb'} // Default country code
                        value={field.value}
                        onChange={(phone) => field.onChange(phone)}
                        inputProps={{
                            name: field.name,
                            ref: field.ref, // Pass the ref to PhoneInput
                        }}
                    />
                )}
            />
            <p className={`form-item-label ${errorText ? 'error' : ''}`}>
                { errorText ? errorText : `${helperText} ${required ? '(required)' : ''}`}
            </p>
        </div>
    );
};
