import './paymentpageviewedittab.scss';

import {
  ReactElement,
  useEffect,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router';

import UserProfileData from '../../classes/UserProfileData';
import LoadingIcon from '../../components/LoadingIcon';
import HelperMethods from '../../helpers/HelperMethods';
import Logger from '../../helpers/Logger';
import {
  AppDispatch,
  RootState,
} from '../../redux/store';
import { getUserProfileDataAsync } from '../../redux/userProfileDataSlice';
import PaymentPageProfile from '../payment/PaymentPageProfile';

const PaymentPageViewEditTab = (): ReactElement => {
  const navigate = useNavigate();
  
  const reduxUserProfileData: UserProfileData | null = useSelector((state: RootState) => state.userProfileDataReducer.userProfileData);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!reduxUserProfileData) {
      Logger.log('getting accountState from PaymentPageViewEditTab');
        dispatch(getUserProfileDataAsync());
    }
  }, [reduxUserProfileData, dispatch]);
  
  if (!reduxUserProfileData) {
    return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
  }

  return (
    <div className='payment-page-preview-wrapper'>
      <div className='payment-page-preview'>
        <PaymentPageProfile
          profileDisplayData={HelperMethods.getProfileDisplayDataFromUserProfileData(reduxUserProfileData)}
          userProfileData={reduxUserProfileData}
          isPreview={true}
        />
      </div>
    </div>
  );
}

export default PaymentPageViewEditTab;
