import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';

import ApiResponse from '../../classes/ApiResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/GetAccountStateFromTokenResponse';
import LoadingIcon from '../../components/LoadingIcon';
import PageDefault from '../../components/PageDefault';
import Constants from '../../configuration/constants';
import LandingPageTwo from './LandingPageTwo';
import SignedInPage from './SignedInPage';

const HomePage = (): ReactElement => {
    const navigate = useNavigate();
    const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined);
    useEffect(() => {
      const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
        return await ApiResponse.getApiResponse(
          Constants.getAccountStateFromTokenEndpoint,
          null,
          GetAccountStateFromTokenResponse,
          null,
          null,
          true
        );
      };
      
      getAccountStateFromToken().then(response => {
        if (!response || response instanceof AxiosError) {
          setIsSignedIn(false);
        } else {
          if (!response.accountCreated) {
            navigate(Constants.createAccountPagePath);
          } else if (!response.stripeOnboardingCompleted) {
            navigate(Constants.connectedAccountPagePath);
          } else {
            setIsSignedIn(true);
          }
        }
      });
    }, [isSignedIn]);

    if (isSignedIn === undefined) {
      return (
        <PageDefault backgroundColor='black'>
          <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
        </PageDefault>
      );
    } else if (isSignedIn === false) {
      return <LandingPageTwo/>;
    } else {
      return <SignedInPage/>;
    }
}

export default HomePage;
