import './landingpagetwo.scss';

import { ReactElement } from 'react';

import { FaCircleCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router';

import NotSignedInNavbar from '../../components/navbar/NotSignedInNavbar';
import PageContent from '../../components/PageContent';
import PageDefault from '../../components/PageDefault';
import PageFooter from '../../components/PageFooter';
import Constants from '../../configuration/constants';

interface LandingPageContentSectionProps {
    title: string;
    content: ReactElement;
}

const LandingPageContentSection = (props: LandingPageContentSectionProps) => {
    const { title, content } = props;

    return (
        <div className='landing-page-content-tile'>
            <div className='landing-page-content-content'>
                <h2>{title}</h2>
                { content }
            </div>
        </div>
    );
}

interface LandingPageImageSectionProps {
    title: string;
    imageSrc: NodeRequire;
    description: string;
}

const LandingPageImageSection = (props: LandingPageImageSectionProps) => {
    const { title, imageSrc, description } = props;
    return (
        <div className='landing-page-image-section'>
            <h2>{title}</h2>
            <img src={imageSrc.toString()}/>
            <p>{description}</p>
        </div>
    );
}

const LandingPageTwo = (): ReactElement => { 
    const navigate = useNavigate();
    
    return (
        <PageDefault backgroundColor='black'>
            <NotSignedInNavbar isLandingPage={true}/>
            <PageContent fullWidth={true}>
                <h1 className='landing-page-heading-text'>THE QR CODE PAYMENTS REVOLUTION HAS ARRIVED</h1>
                <h2 className='landing-page-header-image-text'>Sleek mobile payments, in the palm of their hand</h2>
                <img className='landing-page-header-image' src={require('../../assets/images/landing-page/mock-phone-in-hand.png')}/>
                <LandingPageContentSection
                    title='HOW IT WORKS'
                    content={(
                        <>
                            <p>If you receive tips or donations as part or all of your income, Omnia can help boost how much you earn by enabling you to receive mobile payments, including Apple Pay and Google Pay.</p>
                            <p>Our solution requires no sign-up fee, and creating an account can be completed in just a few simple steps.</p>
                            <div className='landing-page-button' onClick={() => navigate(Constants.authenticatePagePath)}>Sign-up for free</div>
                        </>
                    )}
                />
                <LandingPageImageSection
                    title='Insight-full'
                    imageSrc={require('../../assets/images/landing-page/phone-mockup-data.png')}
                    description='View the data you want to see most with our intuitive Payments dashboards'
                />
                <LandingPageContentSection
                    title='WHO IS IT FOR?'
                    content={(
                        <>
                            <p>Omnia is for anyone who collects payments, including donations and tips, and wants to be able to receive mobile payments in a streamlined and cost efficient way.</p>
                            <div className='landing-page-bullet-list'>
                                { ['Musicians', 'Street Performers', 'Artists', 'Hair Dressers', 'Drivers', 'Cafes', 'Restaurants', 'Charities'].map((item, index) => (
                                    <div key={index} className='landing-page-bullet-list-item'>
                                        <FaCircleCheck className='landing-page-bullet-list-check'/><p>{item}</p>
                                    </div>
                                ))}
                            </div>
                            <p>... and many more</p>
                            <p>Speak to our team today to learn more about how Omnia can help you.</p>
                            <div className='landing-page-button' onClick={() => navigate(Constants.contactPagePath)}>Contact Us</div>
                        </>
                    )}
                />
                <LandingPageImageSection
                    title='Express Yourself'
                    imageSrc={require('../../assets/images/landing-page/customization-mockup-image.png')}
                    description='A fully customisable Profile brings you closer to those who support you'
                />
                <LandingPageContentSection
                    title='ABOUT'
                    content={(
                        <>
                            <p>With more and more transactions going digital and less people carrying cash, we thought it was about time someone made a solution to allow people accept online payments in a sleek, efficient and enjoyable way.</p>
                            <p>We have created Omnia with both the user and the end customer in mind. By doing this we believe we have created the best QR code payments platform available.</p>
                            <div className='landing-page-button' onClick={() => navigate(Constants.faqsPagePath)}>Learn more</div>
                        </>
                    )}
                />
            </PageContent>
            <PageFooter backgroundColour='black'/>
        </PageDefault>
    );
}

export default LandingPageTwo;
