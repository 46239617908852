export class Currency {
    public static readonly GBP = new Currency('gbp', 'GBP', '£');

    private constructor(
        public readonly value: string,
        public readonly displayName: string,
        public readonly symbol: string
    ) {}

    public static getAllCurrencies(): Currency[] {
        return Object.values(Currency).filter(value => value instanceof Currency);
    }
}