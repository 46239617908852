import ProfileDisplayData from '../classes/ProfileDisplayData';
import UserProfileData from '../classes/UserProfileData';
import Constants from '../configuration/constants';
import { Currency } from '../configuration/types';
import { SocialMediaLinks } from '../types/types';

class HelperMethods {
  public static getValidButtonValues(values: number[]): number[] {
    if (!values) {
      return [];
    }
  
    return values
      .filter(value => (Constants.minimumTransactionAmount <= value && value <= Constants.maximumTransactionAmount))
      .map(value => HelperMethods.roundValue(value))
      .sort((a, b) => a - b)
      .slice(0, Constants.maxPaySelectionButtons);
  }

  private static roundValue(value: number): number {
    return Math.round(value*100)/100;
  }

  public static getTotalFee = (value: number): number => {
    const total: number = (value + Constants.paymentProcessingFeeConstant + Constants.omniaFeeConstant) / (1 - Constants.paymentProcessingFeeMultiplier - Constants.omniaFeeMultiplier);

    return total - value;
  }

  public static getValidSocialMediaLinks(obj: SocialMediaLinks): { [k: string]: string; } {
    if (!obj || Object.entries(obj).length === 0) {
      return {};
    } else {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, value]) => value !== null && value !== undefined && value !== '')
          .map(([key, value]) => {
            return [key, HelperMethods.getValidUrl(value)];
          })
      );
    }
  }

  public static getValidUrl(value: string): string {
    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      return 'https://' + value;
    }

    return value;
  }

  public static getValidPhoneNumber(value: string): string {
    if (value && !value.startsWith('+')) {
      return '+' + value;
    }

    return value;
  }

  public static cloneWithUpdates<T>(constructor: new (obj: T) => T, instance: T, updates: Partial<T>): T {
    return new constructor({
      ...instance,
      ...updates
    });
  }

  public static getFeeString(multiplier: number, constant: number, currency: string): string {
    let multiplierString = '';
    let constantString = '';

    if (multiplier > 0) {
      multiplierString = (Math.round(multiplier * 10000) / 100).toString();
      multiplierString = multiplierString.replace(/\.?0+$/, '') + '%';
    }
    if (constant > 0) {
      constantString = HelperMethods.getCurrencyFromString(currency).symbol + (Math.round(constant * 100) / 100).toString();
      constantString = constantString.replace(/\.?0+$/, '');
    }

    return multiplierString + (constantString !== '' ? (' + ' + constantString) : '')
  }

  public static getProfileDisplayDataFromUserProfileData(userProfileData: UserProfileData): ProfileDisplayData {
    return new ProfileDisplayData({
      displayName: userProfileData.displayName,
      username: userProfileData.username,
      stripeConnectedAccountId: userProfileData.stripeConnectedAccountId, // Provide a default value or logic to determine this
      base64ProfileImage: userProfileData.base64ProfileImage,
      suggestedPayValues: userProfileData.suggestedPayValues,
      thankyouMessage: userProfileData.thankyouMessage,
      socialMediaLinks: userProfileData.socialMediaLinks,
      currency: userProfileData.currency,
      qrCodeVerb: userProfileData.qrCodeVerb
    });
  }

  public static getCurrencyFromString(str: string): Currency {
    return Currency.getAllCurrencies().find(currency => currency.value === str.toLowerCase()) || Currency.GBP;
  }
}

export default HelperMethods;
