import './dashboardpage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';

import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import {
  ConnectAccountManagement,
  ConnectComponentsProvider,
  ConnectNotificationBanner,
  ConnectPayments,
  ConnectPayouts,
} from '@stripe/react-connect-js';

import ApiResponse from '../../classes/ApiResponse';
import CreateStripeAccountSessionResponse
  from '../../classes/CreateStripeAccountSessionResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/GetAccountStateFromTokenResponse';
import Constants from '../../configuration/constants';
import StyleVariables from '../../styles/base/variables';

interface DashboardTabItem {
    name: string;
    element: JSX.Element;
  }
  
const tabs: DashboardTabItem[] = [
  {name: 'Payments', element: <ConnectPayments/>},
  {name: 'Payouts', element: <ConnectPayouts/>},
  //{name: 'Documents', element: <ConnectDocuments/>},
  {name: 'Details', element: <ConnectAccountManagement/>}
]

const DashboardPage = (): ReactElement => {
    const navigate = useNavigate();
    
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [stripeConnectInstance] = useState<StripeConnectInstance>(() => {
        const fetchClientSecret = async (): Promise<string> => {
            return await ApiResponse.getApiResponse(
                Constants.createStripeAccountSessionEndpoint,
                null,
                CreateStripeAccountSessionResponse, 
                null,
                null,
                true
            ).then(response => {
                return response ? response.clientSecret : '';
            });
        };

        const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
            return await ApiResponse.getApiResponse(
                Constants.getAccountStateFromTokenEndpoint,
                null,
                GetAccountStateFromTokenResponse,
                null,
                null,
                true
            );
        };
        
        getAccountStateFromToken().then(response => {
            if (!response) {
                navigate(Constants.authenticatePagePath);
            } else if (response instanceof AxiosError) {
                navigate(Constants.homePagePath);
            } else if (!response.accountCreated ) {
                navigate(Constants.createAccountPagePath);
            } else if (!response.stripeOnboardingCompleted) {
                navigate(Constants.connectedAccountPagePath);
            }
        });
        
        /**
         * Appearance options: https://docs.stripe.com/connect/embedded-appearance-options
         */
        return loadConnectAndInitialize({
            publishableKey: Constants.stripePublishableKey,
            fetchClientSecret: fetchClientSecret,
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Kanit:200,300,700&display=swap'
                }
            ],
            appearance: {
                variables: {
                    fontFamily: `${StyleVariables.primaryFontFamily}, Kanit`,
                    colorPrimary: StyleVariables.orangePrimary,
                    colorDanger: StyleVariables.errorRedLight,
                    borderRadius: StyleVariables.pillButtonBorderRadius,
                    colorText: StyleVariables.black,

                    buttonPrimaryColorBackground: StyleVariables.black,
                    buttonPrimaryColorBorder: StyleVariables.black,
                    buttonPrimaryColorText: StyleVariables.white,

                    buttonSecondaryColorBackground: StyleVariables.black,
                    buttonSecondaryColorBorder: StyleVariables.black,
                    buttonSecondaryColorText: StyleVariables.white,

                    actionPrimaryColorText: StyleVariables.orangePrimary,
                    actionSecondaryColorText: StyleVariables.black,
                    badgeNeutralColorBackground: StyleVariables.greyLight,
                    badgeNeutralColorBorder: StyleVariables.greyLight,
                    badgeNeutralColorText: StyleVariables.black,

                    badgeSuccessColorBackground: StyleVariables.successGreenLight,
                    badgeSuccessColorText: StyleVariables.successGreenDark,
                    badgeSuccessColorBorder: StyleVariables.successGreenLight,

                    badgeWarningColorBackground: StyleVariables.errorRedLight,
                    badgeWarningColorText: StyleVariables.errorRedDark,
                    badgeWarningColorBorder: StyleVariables.errorRedLight,

                    formAccentColor: StyleVariables.orangePrimary,

                    buttonBorderRadius: StyleVariables.pillButtonBorderRadius,
                    colorSecondaryText: StyleVariables.greyMid,
                    colorBorder: StyleVariables.black,
            
                    headingLgFontSize: StyleVariables.largeFontSize,
                    headingXlFontSize: StyleVariables.xLargeFontSize,
                    bodySmFontSize: StyleVariables.xSmallFontSize,
                    bodyMdFontSize: StyleVariables.smallFontSize,
                    labelMdFontSize: StyleVariables.smallFontSize,
                    labelSmFontSize: StyleVariables.xSmallFontSize,
                    // headingMdFontSize: '5px',
                    // headingSmFontSize: '',
                    // headingXsFontSize: '',
                    // spacingUnit: ''
                }
            }
        });
    });
    
    return (
        <>
            {/* <DashboardNavbar setDashboardTabIndex={setSelectedTabIndex} selectedIndex={selectedTabIndex}/> */}
            <div className='dashboard-navbar'>
                <div className='dashboard-navbar-content'>
                    {tabs.map((item, index) => (
                        <div key={index} className={`dashboard-navbar-button ${selectedTabIndex === index ? 'selected' : ''}`} onClick={() => setSelectedTabIndex(index)}>{item.name}</div>
                    ))}
                </div>
            </div>
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                <ConnectNotificationBanner />
                <div className='dashboard-tab-content'>
                    {tabs[selectedTabIndex].element}
                </div>
            </ConnectComponentsProvider>
        </>
    );
}

export default DashboardPage;
