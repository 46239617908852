import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import ApiResponse from '../../classes/ApiResponse';
import GetProfileDisplayDataResponse
  from '../../classes/GetProfileDisplayDataResponse';
import ProfileDisplayData from '../../classes/ProfileDisplayData';
import LoadingIcon from '../../components/LoadingIcon';
import PaymentNavbar from '../../components/navbar/PaymentNavbar';
import PageContent from '../../components/PageContent';
import Constants from '../../configuration/constants';
import PaymentConfirmationPageProfile from './PaymentConfirmationPageProfile';

const PaymentConfirmationPage = (): ReactElement => {
  const { username } = useParams();

  const [profileDisplayData, setProfileDisplayData] = useState<ProfileDisplayData|null>(null);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);

  useEffect(() => {
    const fetchProfileDisplayData = async () => {
      return await ApiResponse.getApiResponse(
        `${Constants.getProfileDisplayDataEndpoint}/${username}`,
        null,
        GetProfileDisplayDataResponse,
        null,
        null,
        false);
    };

    if (!profileDisplayData && loadingPage === true) {
      fetchProfileDisplayData().then(response => {
        if (!response || response instanceof AxiosError) {
          return null;
        }
        setProfileDisplayData(response.profileDisplayData);
        setLoadingPage(false);
      });
    }
  }, [profileDisplayData, username, loadingPage]);

  if (loadingPage) {
    return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>
  }
  
  return (
    <>
      <PaymentNavbar/>
      <PageContent>
        {(!profileDisplayData || !profileDisplayData.username) ?
          <>Thank you for using Omnia.</>
          :
          <PaymentConfirmationPageProfile
            profileDisplayData={profileDisplayData}
            isPreview={false}
          />
        }
      </PageContent>
    </>
  );
};

export default PaymentConfirmationPage;
