import './paymentnavbar.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { CiCircleQuestion } from 'react-icons/ci';

import Constants from '../../configuration/constants';
import OmniaModal from '../OmniaModal';
import TextLink from '../TextLink';

const PaymentNavbar = (): ReactElement => {
    const [whatIsOmniaModalVisible, setWhatIsOmniaModalVisible] = useState<boolean>(false);

    const toggleModal = () => {
        setWhatIsOmniaModalVisible(!whatIsOmniaModalVisible);
    }

    return (
        <div className='payment-navbar'>
            <div className='payment-central-navbar'>
                <div className='what-is-omnia-icon-wrapper' onClick={toggleModal}>
                    <CiCircleQuestion className='what-is-omnia-icon'/>
                </div>
                { whatIsOmniaModalVisible &&
                    <OmniaModal
                        modalTitle='What is Omnia?'
                        modalBody={
                            <div>
                                <p className='what-is-omnia-modal-text'>
                                    Omnia is a London based startup founded with the goal of revolutionising how poeple receive mobile payments through the use of QR codes.
                                </p>
                                <p className='what-is-omnia-modal-text'>
                                    We have partnered with the industry leading online payment processor to ensure your mobile transactions are reliable and always secure.
                                </p>
                                <p className='what-is-omnia-modal-text'>
                                    This you might want to use Omnia yourself? Press <TextLink text='here' href={Constants.frontendURL}/> for more info.
                                </p>
                                <p className='what-is-omnia-modal-text'>
                                    Omnia is a UK regstered business.
                                </p>
                            </div>
                          }
                          onClose={toggleModal}
                          isVisible={whatIsOmniaModalVisible}
                    />
                }
                <div className='payment-navbar-logo-wrapper'>
                    <img className='navbar-logo' src={require('../../assets/images/omnia-logos/omnia-logo-black-background.png')} alt='logo'/>
                </div>
            </div>
        </div>
    );
};

export default PaymentNavbar;
