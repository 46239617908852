import axios, {
  AxiosError,
  AxiosResponse,
} from 'axios';
import Cookies from 'js-cookie';

import Constants from '../configuration/constants';
import Logger from '../helpers/Logger';

class ApiResponse<T> {
    data: T | null;
    customerErrorMessage: string;

    constructor(obj: Object, constructor: new (obj: any) => T) {
        const response = obj as ApiResponse<T>;

        this.data = response.data ? new constructor(response.data) : null;
        this.customerErrorMessage = response.customerErrorMessage;
    }

    static async getApiResponse<T, U>(
        endpoint: string,
        requestBody: U,
        constructor: new (obj: T) => T,
        setState: ((obj: T) => void) | null,
        setCustomerErrorMessage: ((customerErrorMessage: string) => void) | null,
        sendJwtToken: boolean): Promise<T|null> {
        const typeName: string = constructor.prototype.constructor.type;
        Logger.log(`Fetching ApiResponse<${constructor.prototype.constructor.type}> from url: ${endpoint}`);
        Logger.log('reqBody: ' + JSON.stringify(requestBody));
        if (sendJwtToken) {
            Logger.log('added token')
            axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get(Constants.jwtTokenName)}`;   
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
        
        let response: AxiosResponse;
        
        try {
            if (requestBody) {
                response = await axios.post(`${Constants.backendURL}/${endpoint}`, requestBody, {});
            } else {
                response = await axios.get(`${Constants.backendURL}/${endpoint}`);
            }
            Logger.log(`API Response: ${JSON.stringify(response)}`);
            
            if (response && response.data === null) {
                return null;
            }
            
            const data = new constructor(response.data);
            Logger.log(`${typeName}: ${JSON.stringify(data)}`);

            Logger.log(`Created ${typeName} instance: ${data}`);
            
            if (setState) {
                setState(data);
            }
    
            return data;
        } catch (response: any) {
            Logger.log(`Failed to get ${typeName} from ${endpoint} due to ${response.response?.data}: ${response}`);
            if (response instanceof AxiosError) {
                Logger.log('Request resulted in AxiosError');
                const errorMessage = response.response?.data;
                if (errorMessage && setCustomerErrorMessage) {
                    setCustomerErrorMessage(errorMessage);
                }
            } else {
                Logger.log('Request resulted in non-AxiosError');
                Logger.log(`Error response: ${response}`);
            }

            return response;
        }
    }
}

export default ApiResponse;
